<template>
  <div class="color rounded-l-full bg-white p-2 pl-4 text-base font-bold shadow-md">
    {{ ambition.title }}
  </div>
</template>

<script setup lang="ts">
import type {Werkveld} from '~/graphql/graphql';

const props = defineProps<{
  ambition: Werkveld
}>();

const color = computed(() => props.ambition.color);
</script>

<style scoped>
.color {
  --color: v-bind(color);

  color: var(--color);
}
</style>

<template>
  <div class="flex h-full flex-col justify-end">
    <NuxtLink
      class="no-underline"
      :to="localePath({name: 'news-slug', params: {slug: article.slug}})"
    >
      <ImgTag
        v-if="article.active_image"
        :image="article.active_image.url"
        :ambition="article.ambition"
        :alt="article.active_image.name"
      />
      <div class="date pb-2.5 pt-4 font-light text-black">
        {{ formatDate(new Date(article.date), 'dd MMMM yyyy') }}
      </div>
      <div class="h-16 sm:h-28">
        <h3 class="heading-sub line-clamp-3 pb-0">
          {{ article.title }}
        </h3>
      </div>
      <span class="text-[#849DBF] underline">
        {{ $t('Read message') }}
      </span>
    </NuxtLink>
  </div>
</template>

<script setup lang="ts">
import {useLocaleDate} from '~/composables/useLocaleDate';
import type {Bericht} from '~/graphql/graphql';

defineProps<{
  article: Bericht
}>();

const formatDate = useLocaleDate();
const localePath = useLocalePath();
</script>
